import { Box, BoxProps, Typography } from '@mui/material'
import Title from '~/src/components/mui-wrappers/Title'

interface Props extends BoxProps<'div'>{
  label: string,
  description: string,
}

const DescribedLabel = ({ label, description, ...boxProps }: Props) => {
  return (
    <Box {...boxProps}>
      <Title variant='h6'>{label}</Title>
      <Typography>
        {description}
      </Typography>
    </Box>
  )
}

export default DescribedLabel
